import reactjs from '../../images/reactjs.png'
import HeaderComponent from "../header";
import FooterComponent from "../footer";
import PaymentComponent from '../payment/payment';
import { useSelector,useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { cartItem } from '../actions';
import LoadingComponent from '../loading';
import { toast,ToastContainer} from 'react-toastify';
const CartComponent = () => {
    const { usersData, isLogin,cartCount,currency,currencyValue } = useSelector((state) => state.LoginLogout);
    const dispatch = useDispatch();
    const [course,setCartCourses] = useState([]);
    const [courseTotal,setCourseTotal] = useState(0);
    const [tax,setTax] = useState(0);
    const [grandTotal,setGrandTotal] = useState(0);
    const [isLoading,setLoading] = useState(false);
    const [voucher,setVoucher] = useState("");
    const [voucherValue,setVoucherValue] = useState(0);
    const [voucherId,setVoucherId] = useState("");

    const clearVoucher = () => 
    {
        setCourseTotal(parseFloat(courseTotal)+parseFloat(voucherValue));
        setVoucherValue(0);
        setVoucherId("");
        localStorage.setItem("version_vid", "");
        localStorage.setItem("version_v_value", "");
                           
    }

    const applyVoucher = () => {
        
        setLoading(true)
        var myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer '+usersData['token']);
            let URL = window.API_URL+"vouchers_value/"+voucher+"/"+usersData.email;
                fetch(URL,
                      {
                        method: "GET",
                        headers: myHeaders,
                      }).then((response) => 
                      {
                        return response.json()
                      }).then((res) => 
                    {
                        setLoading(false)
                        console.log(res);
                        if(res.success === true)
                        {
                            setVoucherValue(res.data.amount);
                            setVoucherId(res.data.id);
                            setCourseTotal(parseFloat(courseTotal)-parseFloat(res.data.amount));
                            localStorage.setItem("version_vid", res.data.id);
                            localStorage.setItem("version_v_value", res.data.amount);
                        }
                        else{
                            toast.error(res.message, {
                                position: toast.POSITION.TOP_RIGHT
                              });
                        }
                    });
    }

    const deleteCart = (id,index) => {
        setLoading(true)
            let URL = window.API_URL+"deleteCart/"+id;
            fetch(URL).then((response) => {
                return response.json()
                }).then((res) => {
                   if(res[0].data.success === 1)
                   {
                    setCourseTotal(courseTotal-course[index].courses.fee);
                    
                    setCartCourses((current) =>
                        current.filter((cart) => cart.id !== id)
                    );
                    dispatch(cartItem(usersData,cartCount-1));    
                   }
                   else
                   {
                        
                        console.log("Unable to deleted");
                   }
                   setLoading(false)
                    
                })

    }

    const convertCurrency = (amount) => {
    
        if(currency === "USD")
        {
            let fee = Math.round(amount*currencyValue);
            return(
              fee
            )
        }
        else{
          return amount
        }
      }

    const getTax = () => {
        
        return 18/100*courseTotal;
    }  

    const getGrandTotal = () => {
        return (18/100*courseTotal)+courseTotal;
    }

    const getMyCourses = () => {

        if(isLogin === true)
        {
            setLoading(true)
            let URL = window.API_URL+"getCartByUser/"+usersData.id;
                fetch(URL).then((response) => {
                return response.json()
                }).then((res) => {
                    let total = 0.0;
                    res.map((course) =>  {

                        total += parseFloat(convertCurrency(course.courses.fee));
                    })
                    setCourseTotal(total);
                    setCartCourses(res);
                    setLoading(false);
                    //setCTax();
                })
        }
    }

    const setCTax = () =>{
        let fTax = 18/100*courseTotal;
        setTax(fTax)
        setGrandTotal(tax+courseTotal)
    }
    
    useEffect(() => {
        localStorage.setItem("version_vid", "");
        localStorage.setItem("version_v_value", "");
        getMyCourses();
      }, [])
    

    if(isLogin !== true)
    {
        window.location.replace(window.SITE_URL);
    }
    else
    {

    return(
        <>
            <LoadingComponent isShow = {isLoading}/>
            <HeaderComponent isHome="false"/>
            
            <div className="container">
            <div className="cadCartContainer">
                <div className="cadCartHeading">
                <h3>Shopping Cart</h3>
                </div>
                <div className="row">
                    <div className="col-md-9">
                    <div className="cadCartCount">
                    {cartCount} Courses in Cart
                    </div>
                    <div className="hrRule"></div>

                    <div className="cadCartItems">

                    

                    {course.length > 0 && (
                          <div className="row">
                  {course.map((course,index) => (
                    <div key={course.id}>
                        <div className="cadCartItem">
                            <div className='cartContainer'>
                                <div className='cartImages'>
                                    <img src={reactjs} width="100%" height="60px" alt='cadBench'/>
                                </div>
                                <div>
                                    <div className='cadCartDetails'>
                                        <h2>{course.courses.course_tile}</h2>
                                    </div>
                                </div>
                                <div>
                                    <div className='cadCartOption'>
                                        <ul>
                                            <li onClick={()=>deleteCart(course.id,index)}>Remove&nbsp;<i className="fa fa-trash" aria-hidden="true"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='cardCartValues'>
                                        <div className='cadCartValue'>

                                        <span><i className={(currency === "INR")?"fa fa-rupee":"fa fa-usd"}></i></span>&nbsp;
                                  {
                                    
                                    convertCurrency(course.courses.fee)
                                     // nf.format(course.fee) // "1,234,567,890"
                                  }
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="hrRule"></div>
                        </div>
                  ))}
                  </div>
              )}
                    </div>

                    </div>

                    <div className="col-md-3">
                        <div className='cadCartCheckout'>
                        <div className="cadCartCount">
                        Total
                        </div>
                        <div className="hrRule"></div>
                        <div className='cadCartLValue'>

                        <i className={(currency === "INR")?"fa fa-rupee":"fa fa-usd"}></i>
                        &nbsp;{
                                            courseTotal
                              }
                        </div>
                        <div className="hrRule"></div>
                        <div className='cartVoucher mb-10'>
                            <div className='flex'>
                            <label className='font-bold mb-10'>Enter Voucher Code</label>
                            {
                                voucherValue > 0 && (
                                    <span className='clearVoucher cursor-pointer' onClick={()=>clearVoucher()}>Clear</span>
                                )
                            }
                            
                            </div>
                            <input 
                            value={voucher}
                            onChange={(e)=>setVoucher(e.target.value)}
                            type='text' 
                            className='form-control' 
                            width="100%"
                            disabled={voucherValue == 0?false:true}
                            />
                            {
                                (voucherValue == 0) && (
                            <button 
                            onClick={()=>applyVoucher()} 
                            className='cadCheckOutBtn border-none'
        
                            >Apply Voucher</button>  
                                )
                            }
                            
                        </div>
                        <div className='cartCadTax'>
                            <div>Voucher Value</div>
                            <div><i className={(currency === "INR")?"fa fa-rupee":"fa fa-usd"}></i>&nbsp;{voucherValue}</div>
                        </div>
                        <div className="hrRule"></div>
                        <div className='cartCadTax'>
                            <div>Tax(18%)</div>
                            <div><i className={(currency === "INR")?"fa fa-rupee":"fa fa-usd"}></i>
                        &nbsp;{
                                            getTax()
                              }</div>
                        </div>
                        <div className="hrRule"></div>
                        <div className='cartGrandTax'>
                            <div>Grand Total</div>
                            <div><i className={(currency === "INR")?"fa fa-rupee":"fa fa-usd"}></i>
                        &nbsp;{
                                            getGrandTotal()
                              }</div>
                        </div>
                        <div className='cadCheckOut'>
                           {
                            cartCount>0 &&
                            (
                                <PaymentComponent totalAmount={getGrandTotal()} voucher={voucherId} voucher_value={voucherValue} userData={usersData} currency={currency}/>
                            )
                           }
                            
                        </div>
                    
                        </div>
                    </div>
                    </div>
            </div>
            </div>
            <FooterComponent/>
        </>
    )
    }
}
export default CartComponent;